/**
 * Register Google
 */
import PropTypes from 'prop-types';
import { useEffect, useRef } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import Api from '@/configs/Api';
import firebaseMessaging from '@/core/helpers/firebaseMessaging';
import useRequest from '@/core/api/useRequest';

import { firebaseConfig } from "@/configs/Firebase";
import { setSocialAuth, getRedirectTo, setReferral } from "@/core/store/reducer/loginSlice";
import { getFcmToken, setAuthData } from '@/core/store/reducer/authSlice';
import Image from "@/core/components/Image";
import { logAction } from '@/core/helpers/analytics';
import { setResponseAction, showError } from '@/core/store/reducer/apiSlice';
import { useState } from 'react';
import Recaptcha from '@/core/components/Recaptcha';

export const bntStyles = 'w-full flex items-center justify-center font-medium py-4 px-6 rounded-full border border-neutral-30';

// Initialize Firebase 
const fbApp = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const auth = getAuth(fbApp);

const ButtonGoogle = ({ setLoading, isLoginPage, onSuccessLogin, onRegistered, setShowRecaptchaSocial }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const reduxRedirect = useSelector(getRedirectTo);
  const searchParams = useSearchParams();
  const redirect = searchParams?.get('redirect') ? decodeURI(searchParams?.get('redirect')) : null;
  const socialDataRef = useRef(null);

  const [getLoadingLogin, setLoadingLogin] = useState(false);

  const [validTokenV3, setValidTokenV3] = useState(true);
  const [tokenRecaptchaV2, setTokenRecaptchaV2] = useState(null);

  let fcmToken = useSelector(getFcmToken);
  const { requestGet, requestPost } = useRequest();

  const pathname = usePathname();

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Detect loading
  useEffect(() => {
    if (getLoadingLogin) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getLoadingLogin]);

  /**
   * onClickRegister
   */
  const onClickRegister = () => {
    logAction('AUTH_LS_LoginGoogle');
    signInWithPopup(auth, provider)
      .then((result) => {
        dispatch(setSocialAuth({
          type: 'google',
          isLogin: false,
          data: result?.user,
          idToken: result?.user?.accessToken,
        }));

        socialDataRef.current = result?.user;

        const params = {
          email: result?.user?.email
        }

        // Check user first
        requestGet(Api.CHECK_USER_REGISTERED(), params).then(async () => {
          if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");

            window.location.reload();
            
            return false;
          }

          try {
            let token = null;

            if (validTokenV3) token = await executeRecaptcha('LoginGoogle');
            else token = tokenRecaptchaV2;

            if (socialDataRef.current) {

              setLoadingLogin(true);

              if (!fcmToken) fcmToken = await firebaseMessaging.init(fbApp);  

              const paramsLogin = {
                idToken: socialDataRef.current?.accessToken,
                fcmToken: fcmToken,
                recaptchaToken: token,
                recaptchaVersion: validTokenV3 ? 'v3' : 'v2'
              }

              requestPost(Api.LOGIN_GOOGLE(), paramsLogin).then(res => {
                dispatch(setAuthData(res?.data));
                onSuccessLogin();

                setTimeout(() => {
                  dispatch(setResponseAction({
                    message: <>
                      <p>Berhasil masuk</p>
                    </>,
                    status: true,
                  }));
                  setValidTokenV3(true);

                  router?.push(reduxRedirect || redirect || `/user`);
                }, 1000);
              }).catch(err => {
                const errorException = err?.response?.data?.ExceptionType;

                if (errorException === 'CaptchaInvalidException') {
                  setValidTokenV3(false);

                  setShowRecaptchaSocial('google');
                } else {
                  dispatch(showError({ error: err }));
                }

                setLoadingLogin(false);
                setTokenRecaptchaV2(null);
              });
            }
          } catch (error) {
            console.log(error?.message);
          }
        }).catch(() => {
          if (pathname?.includes(process.env.NEXT_PUBLIC_IDEAL_OLX_URL)) {
            dispatch(setReferral(process.env.NEXT_PUBLIC_IDEAL_OLX_REFERRAL))
          } else if (pathname?.includes(process.env.NEXT_PUBLIC_IDEAL_RUMAH123_URL)) {
            dispatch(setReferral(process.env.NEXT_PUBLIC_IDEAL_RUMAH123_REFERRAL))
          } else {
            dispatch(setReferral(''))
          }

          router.push(pathname?.split('?')[1] ? `/register/social?${pathname?.split('?')[1]}` : '/register/social');

          onRegistered();
        });
      })
      .catch((error) => {
        console.log('google sign up error', error);
      })
  };

  return (
    <div className='w-full'>

      {!validTokenV3 ?
        <Recaptcha classNameContainer='mb-6 mx-auto relative w-fit' token={tokenRecaptchaV2} onChange={token => setTokenRecaptchaV2(token)} />
        : null
      }

      <button
        disabled={!validTokenV3 && !tokenRecaptchaV2}
        type="button"
        className={`
          w-full flex items-center justify-center font-medium rounded-full border border-neutral-30
          ${isLoginPage ? 'py-4 px-2' : 'py-4 px-6'}
        `}
        onClick={onClickRegister}
      // disabled={false}
      >
        <Image src={'/images/icons/IconGoogle.svg'} alt="Google" className={`w-5`} height={20} width={20} />
        <span className={`ml-2 ${isLoginPage ? 'text-2xs' : 'text-sm'}`}>
          {isLoginPage ? 'Masuk Dengan Google' : 'Daftar Dengan Google'}
        </span>
      </button>
    </div>
  );
};

ButtonGoogle.propTypes = {
  isLoginPage: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  onRegistered: PropTypes.func.isRequired,
  onSuccessLogin: PropTypes.func.isRequired,
};

export default ButtonGoogle;