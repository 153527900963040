import React, { useEffect, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

/**
 * @param {{
 * classNameContainer: string;
 * classNameContainerAdditional: string;
 * onChange: (token: string) => void | undefined;
 * token: string | null;
 * }} props
 */

window.recaptchaOptions = {
    useRecaptchaNet: true,
    enterprise: true,
};

export default function Recaptcha(props) {

    const {
        classNameContainer = 'mt-10 mx-auto relative w-fit',
        classNameContainerAdditional = '',
        onChange = undefined,
        siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_V2_SITE_KEY,
        token = null,
    } = props;

    const refRecaptcha = useRef();

    useEffect(() => {
        if (!token && refRecaptcha.current.value) refRecaptcha.current.reset();
    }, [token, refRecaptcha]);

    return (
        <div className={[classNameContainer, classNameContainerAdditional]?.filter(Boolean)?.join(' ')}>
            <ReCAPTCHA
                ref={refRecaptcha}
                sitekey={siteKey}
                onChange={onChange}
            />
        </div>
    )
}
